import React, { Component } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import TaskNew from './components/task-new/';
import TaskView from './components/task-view/';
import TaskAssign from './components/task-assign/';
import TaskMap from './components/task-map/';
import TaskOffline from './components/task-offline/';
import TaskOfflineMap from './components/task-offline-map/';
import TaskDeployable from './components/task-deployable/';
import TaskFallen from './components/task-fallen/';
import TaskFallenMap from './components/task-fallen-map/';
import TaskActive from './components/task-active/';
import TaskPriority from './components/task-priority/';
import TaskRoute from './components/task-route/';
import TaskRouteNew from './components/task-route-new/';
import TaskHelmetMap from './components/task-helmet-map/';
import TaskTransits from './components/task-transits/';
import TaskUnlocked from './components/task-unlocked/';
import TaskAlerts from './components/task-alerts/';
import TaskScans from './components/task-scans/';

class Task extends Component {
  render() {
    const lastRoute = `/task/${ localStorage.getItem('fm-task') || 'active' }`;
    return (
      <Switch>
        <Route path="/task/new/:vehicleId?/:type?" component={TaskNew} />
        <Route path="/task/route/new" component={TaskRouteNew} />
        <Route path="/task/route/:taskIds" component={TaskRoute} />
        <Route path="/task/map" component={TaskMap} />
        <Route path="/task/offline/map" component={TaskOfflineMap} />
        <Route path="/task/offline" component={TaskOffline} />
        <Route path="/task/deployable" component={TaskDeployable} />
        <Route path="/task/fallen/map" component={TaskFallenMap} />
        <Route path="/task/fallen" component={TaskFallen} />
        <Route path="/task/helmet" component={TaskHelmetMap} />
        <Route path="/task/active" component={TaskActive} />
        <Route path="/task/transits" component={TaskTransits} />
        <Route path="/task/priority" component={TaskPriority} />
        <Route path="/task/unlocked" component={TaskUnlocked} />
        <Route path="/task/alerts" component={TaskAlerts} />
        <Route path="/task/scans" component={TaskScans} />
        <Route path="/task/:taskId/assign" component={TaskAssign} />
        <Route path="/task/:taskId" component={TaskView} />
        <Redirect from='/task' to={lastRoute} />
      </Switch>
    );
  }
}

export default Task;
