import React, { Component } from 'react';
import moment from 'moment';
import NavigationBar from 'components/common/navigation-bar';
import { Link } from 'react-router-dom';
import Toast from 'components/common/toast';
import api from 'helpers/api';

class AnalyticsComplaints extends Component {
  constructor() {
    super();
    const today = moment().format('YYYY-MM')
    this.state = { month: today, today, data: false, loading: false };

    this.loadData = this.loadData.bind(this);
    this.handleError = this.handleError.bind(this);
    this.handleDateChange = this.handleDateChange.bind(this);
  }

  componentDidMount() {
    document.title = 'Complaints | Flamingo Admin';
  }

  loadData() {
    this.setState({ loading: true });
    const month = this.state.month;
    return api.get(`/support/complaints?month=${month}`)
      .then((res) => this.setState({ data: res.data.data, loading: false }))
      .catch(this.handleError);
  }

  handleError(err) {
    const errors = window.access(() => err.response.data.error.errors) ? err.response.data.error.errors : [];
    const error = window.access(() => errors[0].message) ? errors[0].message : (window.access(() => err.response.data.error.message) ? err.response.data.error.message : err.toString());
    this.setState({ error, loading: false });
  }

  handleDateChange(e) {
    this.setState({ month: e.target.value });
  }

  render() {
    const { loading, data, month, today } = this.state;

    return (
      <div className="fm-analytics">
        <NavigationBar title="Complaints" loading={loading} />
        <div className="fm-analytics-complaints">
          <div className="fm-analytics-complaints-form">
            <input value={month} className="fm-input" type="month" onChange={this.handleDateChange} max={today} />
            <button className="fm-analytics-complaints-button" onClick={this.loadData}>{ loading ? 'Loading...' : 'Load Data' }</button>
          </div>
          {
            data ? (
              <table className="fm-analytics-complaints-table">
                <tbody>
                  {
                    data.map((complaint, i) => {
                      return (
                        <tr key={i}>
                          <td className="fm-analytics-complaints-table-item fm-analytics-complaints-table-item-contact">
                            <Link to={`/support/ticket/${complaint.id}`}>
                              <p className="fm-analytics-complaints-table-subject">{ complaint.subject }</p>
                              <p className="fm-analytics-complaints-table-name">{ complaint.name }</p>
                            </Link>
                          </td>
                          <td className="fm-analytics-complaints-table-item fm-analytics-complaints-table-item-details">
                            <Link to={`/support/ticket/${complaint.id}`}>
                              <p className="fm-analytics-complaints-table-date">{ moment(complaint.createdAt).format('H:mm D/M/YY') }</p>
                              <p className="fm-analytics-complaints-table-region">{ complaint.region }</p>
                            </Link>
                          </td>
                          <td className="fm-analytics-complaints-table-item fm-analytics-complaints-table-item-duration">
                            <Link to={`/support/ticket/${complaint.id}`}>
                              <p className="fm-analytics-complaints-table-duration">{ complaint.duration ? `${complaint.duration} mins` : '' }</p>
                            </Link>
                          </td>
                          <td className="fm-analytics-complaints-table-item fm-analytics-complaints-table-item-content">
                            <Link to={`/support/ticket/${complaint.id}`}>
                              <p className="fm-analytics-complaints-table-content">{ complaint.content }</p>
                            </Link>
                          </td>
                        </tr>
                      );
                    })
                  }
                </tbody>
              </table>
            ) : (
              <p className="fm-analytics-complaints-empty">{ loading ? 'Please wait...' : 'Select the month above.' }</p>
            )
          }
        </div>
        { this.state.error && <Toast>{this.state.error}</Toast> }
      </div>
    );
  }
}

export default AnalyticsComplaints;
