import React, { Component } from 'react';
import NavigationBar from 'components/common/navigation-bar';
import Map, { Polygon } from 'components/common/map';
import Toast from 'components/common/toast';
import api from 'helpers/api';
import colors from 'helpers/colors';
import moment from 'moment';

const types = [
  { value: 'NORIDING', label: 'No Riding Zone' },
  { value: 'NOPARKING', label: 'No Parking Zone' },
  { value: 'LOWSPEED', label: 'Low Speed Zone' },
  { value: 'LOWSPEEDNOPARKING', label: 'Low Speed No Parking Zone' },
  { value: 'NOHIRE', label: 'No Hire Zone' },
  { value: 'PUBLICTRANSPORT', label: 'Public Transport Zone' },
  { value: 'HIDDEN', label: 'Hidden Zone' },
  { value: 'REMINDER', label: 'Reminder Zone' },
];

const speedLimits = [
  { value: null, label: 'No Speed Limit' },
  { value: 6, label: '6 km/h' },
  { value: 10, label: '10 km/h' },
  { value: 15, label: '15 km/h' },
  { value: 20, label: '20 km/h' },
  { value: 25, label: '25 km/h' },
];

const booleans = [
  { value: true, label: 'Yes' },
  { value: false, label: 'No' },
];

const times = ['00:00:00','00:30:00','01:00:00','01:30:00','02:00:00','02:30:00','03:00:00','03:30:00','04:00:00','04:30:00','05:00:00','05:30:00','06:00:00','06:30:00','07:00:00','07:30:00','08:00:00','08:30:00','09:00:00','09:30:00','10:00:00','10:30:00','11:00:00','11:30:00','12:00:00','12:30:00','13:00:00','13:30:00','14:00:00','14:30:00','15:00:00','15:30:00','16:00:00','16:30:00','17:00:00','17:30:00','18:00:00','18:30:00','19:00:00','19:30:00','20:00:00','20:30:00','21:00:00','21:30:00','22:00:00','22:30:00','23:00:00','23:30:00','24:00:00'];

const fieldLabels = {
  isActive: 'Active',
  canPark: 'Can Park',
  canRide: 'Can Ride',
  canHire: 'Can Hire',
  startTime: 'Start Time',
  endTime: 'End Time',
  speedLimit: 'Speed Limit',
  type: 'Type',
  message: 'Message',
  monday: 'Monday',
  tuesday: 'Tuesday',
  wednesday: 'Wednesday',
  thursday: 'Thursday',
  friday: 'Friday',
  name: 'Name',
};

const valueLabels = {
  'true': 'Yes',
  'false': 'No',
  '6': '6 km/h',
  '10': '6 km/h',
  '15': '15 km/h',
  '20': '20 km/h',
  '25': '25 km/h',
  'NULL': 'None',
};

class ZoneView extends Component {
  constructor(props) {
    super(props);
    const zone = props.location.state ? props.location.state.zone : {};

    this.state = {
      zone,
      changes: [],
      editing: false,
      error: false,
      loading: !zone.id,
    };

    this.loadZone = this.loadZone.bind(this);
    this.loadZoneChanges = this.loadZoneChanges.bind(this);
    this.onEdit = this.onEdit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.handleError = this.handleError.bind(this);
  }

  componentDidMount() {
    document.title = 'Zone Details | Flamingo Admin';
    if (!this.state.zone.id) {
      this.loadZone();
    } else {
      this.loadZoneChanges();
    }
  }

  /* ------ Networking ------ */

  loadZone() {
    return api.get('/zone/' + this.props.match.params.zoneId)
      .then((res) => this.setState({ zone: res.data.data, loading: false }, this.loadZoneChanges))
      .catch(this.handleError);
  }

  loadZoneChanges() {
    return api.get('/zone/changes?zoneId=' + this.props.match.params.zoneId)
      .then((res) => this.setState({ changes: res.data.data }))
      .catch(this.handleError);
  }

  handleError(e) {
    const validation = window.access(() => e.response.data.validation[0]) ? `Invalid ${e.response.data.validation[0]}` : false;
    const error = window.access(() => e.response.data.code) ? e.response.data.code : 'Something went wrong';
    this.setState({ error: validation || error, loading: false });
  }

  /* ------ Controls ------ */

  onEdit() {
    this.setState({ editing: !this.state.editing, editableZone: { ...this.state.zone } });
  }

  handleChange(e) {
    const { editableZone } = this.state;
    const key = e.target.getAttribute('field');
    editableZone[key] = e.target.value === 'true' ? true : (e.target.value === 'false' ? false : (e.target.value === 'null' ? null : (isNaN(e.target.value) ? e.target.value : parseInt(e.target.value))));
    this.setState({ editableZone, error: false });
  }

  handleSave() {
    const { editableZone } = this.state;
    this.setState({ loading: true });
    return api.post(`/zone/${this.props.match.params.zoneId}`, editableZone)
      .then((res) => this.setState({ zone: res.data.data, loading: false, editing: false }))
      .catch(this.handleError);
  }

  /* ------ Renders ------ */

  activeDays(zone) {
    let days = [];
    if (zone.monday) {
      days.push('Monday');
    }
    if (zone.tuesday) {
      days.push('Tuesday');
    }
    if (zone.wednesday) {
      days.push('Wednesday');
    }
    if (zone.thursday) {
      days.push('Thursday');
    }
    if (zone.friday) {
      days.push('Friday');
    }
    if (zone.saturday) {
      days.push('Saturday');
    }
    if (zone.sunday) {
      days.push('Sunday');
    }
    return days.length === 7 ? 'Everyday' : days.join(', ');
  }

  restrictions(zone) {
    var restrictions = [];
    ['riding', 'parking', 'hiring'].forEach(function(type, i) {
      if (!zone[['canRide', 'canPark', 'canHire'][i]]) {
        restrictions.push(type);
      }
    });
    var speedLimit = zone.speedLimit ? zone.speedLimit + 'km/h speed limit' : '';
    if (restrictions.length > 0) {
      restrictions = ['No ' + restrictions.slice(0, -1).join(', ') + (restrictions.length > 1 ? ' or ' : '') + restrictions.slice(-1)];
    }
    if (speedLimit) {
      restrictions.push(speedLimit);
    }
    return restrictions.length ? restrictions.join(' and ') : 'No restrictions';
  }

  renderChange(change, i) {
    return (
      <div className="fm-zone-changes-item" key={i}>
        <p className="fm-zone-changes-item-field">{ fieldLabels[change.field] }</p>
        <p className="fm-zone-changes-item-value">{ valueLabels[change.newValue] || change.newValue }</p>
        <p className="fm-zone-changes-item-date">{ moment(change.changeAt).format('HH:mm DD-MM-YYYY') }</p>
      </div>
    );
  }

  render() {
    const { loading, zone, editableZone, editing, changes, error } = this.state;
    const editAction = { onClick: this.onEdit, icon: editing ? 'times' : 'edit', title: 'Edit' };
    const polygonAction = { to: { pathname: `/zone/${zone.id}/polygon`, state: { zone } }, icon: 'draw-polygon', title: 'Edit Polygon' };
    const changesAction = { to: `/zone/${zone.id}/schedule`, icon: 'clock' };

    return (
      <div className="fm-zone-view">
        <NavigationBar title={ zone.name || 'Zone' } loading={loading} showBack={!editing} rightActions={[changesAction, polygonAction, editAction]} />
        {
          zone.id &&
          <>
            {
              editing ? (
                <div className="fm-zone-view-edit">
                  <div className="fm-zone-view-input">
                    <p className="fm-input-label">Name</p>
                    <input value={editableZone.name} className="fm-input" field="name" onChange={this.handleChange} />
                  </div>
                  <div className="fm-zone-view-input">
                    <p className="fm-input-label">Description</p>
                    <input value={editableZone.message} className="fm-input" field="message" onChange={this.handleChange} />
                  </div>
                  <div className="fm-zone-view-input">
                    <p className="fm-input-label">Type (For App Map)</p>
                    <select value={editableZone.type} className="fm-input fm-input-select" field="type" onChange={this.handleChange}>
                      { types.map((type, i) => <option key={i} value={type.value}>{type.label}</option>) }
                    </select>
                  </div>
                  <div className="fm-zone-view-input">
                    <p className="fm-input-label">Speed Limit</p>
                    <select value={editableZone.speedLimit || 'null'} className="fm-input fm-input-select" field="speedLimit" onChange={this.handleChange}>
                      { speedLimits.map((speedLimit, i) => <option key={i} value={speedLimit.value || 'null'}>{speedLimit.label}</option>) }
                    </select>
                    <p className="fm-input-hint">Use 6km/h for No Riding Zones, 15km/h for Low Speed Zones and No Speed Limit for all other zones.</p>
                  </div>
                  <div className="fm-zone-view-input">
                    <p className="fm-input-label">Active</p>
                    <select value={editableZone.isActive} className="fm-input fm-input-select" field="isActive" onChange={this.handleChange}>
                      { booleans.map((option, i) => <option key={i} value={option.value}>{option.label}</option>) }
                    </select>
                    <p className="fm-input-hint">Use "No" if further setup is required, like time/day restrictions.</p>
                  </div>
                  <div className="fm-zone-view-input">
                    <p className="fm-input-label">Can Hire (Start Trip)</p>
                    <select value={editableZone.canHire} className="fm-input fm-input-select" field="canHire" onChange={this.handleChange}>
                      { booleans.map((option, i) => <option key={i} value={option.value}>{option.label}</option>) }
                    </select>
                    <p className="fm-input-hint">Usually Yes, unless this is a No Hire Zone.</p>
                  </div>
                  <div className="fm-zone-view-input">
                    <p className="fm-input-label">Can Ride (Throttle Enabled)</p>
                    <select value={editableZone.canRide} className="fm-input fm-input-select" field="canRide" onChange={this.handleChange}>
                      { booleans.map((option, i) => <option key={i} value={option.value}>{option.label}</option>) }
                    </select>
                    <p className="fm-input-hint">Usually Yes, unless this is a No Riding Zone.</p>
                  </div>
                  <div className="fm-zone-view-input">
                    <p className="fm-input-label">Can Park (End Trip)</p>
                    <select value={editableZone.canPark} className="fm-input fm-input-select" field="canPark" onChange={this.handleChange}>
                      { booleans.map((option, i) => <option key={i} value={option.value}>{option.label}</option>) }
                    </select>
                    <p className="fm-input-hint">Set to No for No Parking and No Riding Zones.</p>
                  </div>
                  <div className="fm-zone-view-input">
                    <p className="fm-input-label">Start Time</p>
                    <select value={editableZone.startTime} className="fm-input fm-input-select" field="startTime" onChange={this.handleChange}>
                      { times.map((option, i) => <option key={i} value={option}>{option}</option>) }
                    </select>
                    <p className="fm-input-hint">Use 00:00:00 and 24:00:00 for all day. For temporary zones, use scheduled changes rather than start/end times.</p>
                  </div>
                  <div className="fm-zone-view-input">
                    <p className="fm-input-label">End Time</p>
                    <select value={editableZone.endTime} className="fm-input fm-input-select" field="endTime" onChange={this.handleChange}>
                      { times.map((option, i) => <option key={i} value={option}>{option}</option>) }
                    </select>
                    <p className="fm-input-hint">Use 00:00:00 and 24:00:00 for all day. For temporary zones, use scheduled changes rather than start/end times.</p>
                  </div>
                  <div className="fm-zone-view-input">
                    <p className="fm-input-label">Active on Monday</p>
                    <select value={editableZone.monday} className="fm-input fm-input-select" field="monday" onChange={this.handleChange}>
                      { booleans.map((option, i) => <option key={i} value={option.value}>{option.label}</option>) }
                    </select>
                  </div>
                  <div className="fm-zone-view-input">
                    <p className="fm-input-label">Active on Tuesday</p>
                    <select value={editableZone.tuesday} className="fm-input fm-input-select" field="tuesday" onChange={this.handleChange}>
                      { booleans.map((option, i) => <option key={i} value={option.value}>{option.label}</option>) }
                    </select>
                  </div>
                  <div className="fm-zone-view-input">
                    <p className="fm-input-label">Active on Wednesday</p>
                    <select value={editableZone.wednesday} className="fm-input fm-input-select" field="wednesday" onChange={this.handleChange}>
                      { booleans.map((option, i) => <option key={i} value={option.value}>{option.label}</option>) }
                    </select>
                  </div>
                  <div className="fm-zone-view-input">
                    <p className="fm-input-label">Active on Thursday</p>
                    <select value={editableZone.thursday} className="fm-input fm-input-select" field="thursday" onChange={this.handleChange}>
                      { booleans.map((option, i) => <option key={i} value={option.value}>{option.label}</option>) }
                    </select>
                  </div>
                  <div className="fm-zone-view-input">
                    <p className="fm-input-label">Active on Friday</p>
                    <select value={editableZone.friday} className="fm-input fm-input-select" field="friday" onChange={this.handleChange}>
                      { booleans.map((option, i) => <option key={i} value={option.value}>{option.label}</option>) }
                    </select>
                  </div>
                  <div className="fm-zone-view-input">
                    <p className="fm-input-label">Active on Saturday</p>
                    <select value={editableZone.saturday} className="fm-input fm-input-select" field="saturday" onChange={this.handleChange}>
                      { booleans.map((option, i) => <option key={i} value={option.value}>{option.label}</option>) }
                    </select>
                  </div>
                  <div className="fm-zone-view-input">
                    <p className="fm-input-label">Active on Sunday</p>
                    <select value={editableZone.sunday} className="fm-input fm-input-select" field="sunday" onChange={this.handleChange}>
                      { booleans.map((option, i) => <option key={i} value={option.value}>{option.label}</option>) }
                    </select>
                  </div>
                  <button className="fm-task-new-button" onClick={this.handleSave}>{ loading ? 'Saving...' : 'Save' }</button>
                </div>
              ) : (
                <div className="fm-zone-view-details">
                  {
                    zone.polygon &&
                    <div className="fm-zone-view-details-map">
                      <Map google={window.google} options={{ center: { lat: zone.polygon[0].latitude, lng: zone.polygon[0].longitude }, zoom: 14 }}>
                        <Polygon paths={ zone.polygon.map((p) => ({ lat: p.latitude, lng: p.longitude })) } strokeColor={colors.zone[zone.type]} strokeWeight={2} fillColor={colors.zone[zone.type]} fillOpacity={0.2} />
                      </Map>
                    </div>
                  }
                  <div className="fm-zone-view-details-items">
                    <p className="fm-zone-view-details-message">{ zone.message }</p>
                    <p className="fm-zone-view-details-type" style={{ backgroundColor: colors.zone[zone.type] }}>{ zone.type }</p>
                    <p className="fm-zone-view-details-value"><span className="fm-zone-view-details-key">Active:</span> { zone.isActive ? 'Yes' : 'No' }</p>
                    <p className="fm-zone-view-details-value"><span className="fm-zone-view-details-key">Restrictions:</span> { this.restrictions(zone) }.</p>
                    <p className="fm-zone-view-details-value"><span className="fm-zone-view-details-key">Timing:</span> { this.activeDays(zone) } { zone.startTime.substring(0, 5) } to { zone.endTime.substring(0, 5) }.</p>
                  </div>
                  {
                    changes.length > 0 &&
                    <div className="fm-zone-view-details-changes">
                      <p className="fm-zone-view-details-changes-title">Scheduled Changes</p>
                      { changes.map(this.renderChange) }
                    </div>
                  }
                </div>
              )
            }
          </>
        }
        { error && <Toast>{error}</Toast> }
      </div>
    );
  }
}

export default ZoneView;
