import React, { Component } from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';
import NavigationBar from 'components/common/navigation-bar';
import Toast from 'components/common/toast';
import Icon from 'components/common/icon';
import colors from 'helpers/colors';
import api from 'helpers/api';

class Dashboard extends Component {
  constructor() {
    super();
    this.state = { data: {}, loading: true };

    this.loadData = this.loadData.bind(this);
    this.handleError = this.handleError.bind(this);
  }

  componentDidMount() {
    document.title = 'Dashboard | Flamingo Admin';
    this.loadData();
  }

  // Networking

  loadData() {
    this.setState({ loading: true });
    return api.get('/dashboard')
      .then((res) => this.setState({ data: res.data.data, loading: false }))
      .catch(this.handleError);
  }

  handleError(e) {
    const error = window.access(() => e.response.data.code) ? e.response.data.code : 'Something went wrong';
    this.setState({ error, loading: false });
  }

  // Renders

  renderItem(count, link, title, icon, backgroundColor) {
    if (count && count !== 0) {
      return (
        <Link className="fm-dashboard-item" to={link}>
          <div className="fm-dashboard-item-icon" style={{ backgroundColor }}>
            <Icon icon={icon} />
          </div>
          <div className="fm-dashboard-item-text">
            <p className="fm-dashboard-item-title">{ title }</p>
            <p className="fm-dashboard-item-count">{ count }</p>
          </div>
        </Link>
      );
    }
    return null;
  }

  renderRegion(region, i) {
    const barWidth = parseInt(region.prowTotal / region.cap * 100) || 0;
    const barColor = barWidth >= 100 ? '#e84118' : (barWidth >= 95 ? '#f0932b' : undefined);
    return (
      <div className="fm-dashboard-region" key={i}>
        <p className="fm-dashboard-region-name">{ region.name } <span className="fm-dashboard-region-type">({ region.tier ? `${region.tier} - ${region.type}` : region.type }s)</span></p>
        <div className="fm-dashboard-region-counts">
          <div className="fm-dashboard-region-counts-inner">
            <p className="fm-dashboard-region-inuse">{ region.inUseTotal || 0 }</p>
            <p className="fm-dashboard-region-slash">/</p>
            <p className="fm-dashboard-region-total">{ region.hireableTotal || 0 }</p>
          </div>
          <div className="fm-dashboard-region-counts-bar" style={{ width: `${barWidth}%`, backgroundColor: barColor }}></div>
        </div>
      </div>
    );
  }

  renderEvent(evt, i) {
    const startDate = moment(evt.startTime).format('ddd D MMM');
    const endDate = moment(evt.endTime).format('ddd D MMM');
    let periodLabel = startDate === endDate ? `on ${startDate}` : `from ${startDate} to ${endDate}`;
    const hoursUntil = moment(evt.startTime).diff(undefined, 'hours');
    if (hoursUntil <= 0) {
      periodLabel = `valid for ${moment(evt.endTime).toNow(true)}`;
    } else if (hoursUntil <= 168) {
      periodLabel = `in ${moment(evt.startTime).toNow(true)} (${startDate === endDate ? startDate : `from ${startDate} to ${endDate}`})`;
    }
    return (
      <div className="fm-dashboard-event" key={i}>
        <div className="fm-dashboard-event-header" key={i}>
          <p className="fm-dashboard-event-name">{ evt.name }</p>
          <p className="fm-dashboard-event-dates">{ periodLabel }</p>
        </div>
        <div className="fm-dashboard-event-tags">
          { evt.tags.map((tag, ii) => <div key={ii} className="fm-dashboard-event-tag" style={{ color: hoursUntil <= 0 ? colors.regionEvents[tag] : undefined }}>{ tag }</div>) }
        </div>
      </div>
    );
  }

  render() {
    const { loading, data, error } = this.state;
    const refreshAction = { onClick: this.loadData, icon: 'sync-alt' };

    return (
      <div className="fm-dashboard">
        <NavigationBar title="Dashboard" loading={loading} rightActions={[refreshAction]} />
        <div className="fm-dashboard-items">
          { this.renderItem(data.pendingTickets, '/ticket', 'Pending Tickets', 'exclamation-triangle', '#EB3B5A') }
          { this.renderItem(data.scans, '/task/scans', 'Vehicle Scans', 'qrcode', '#F1C40F') }
          { this.renderItem(data.supportTickets, '/support/unassigned', 'Support Tickets', 'life-ring', '#EB3B5A') }
          { this.renderItem(data.reports, '/report', 'Reports', 'flag', '#4B7BEC') }
          { data.lastCall && this.renderItem(moment(data.lastCall.createdAt).fromNow(), data.lastCall.associatedUserId ? `/user/${data.lastCall.associatedUserId}` : '/support/calls', data.lastCall.name || data.lastCall.phone, 'phone', '#34495E') }
          { this.renderItem(data.offlineVehicles, '/task/offline', 'Offline Vehicles', 'satellite-dish', '#e67e22') }
          { this.renderItem(data.transits, '/task/transits', 'Street Transits', 'question-circle', '#7f8c8d') }
          { this.renderItem(data.parkingOffences, '/report/category/zone', 'Parking Offences', 'parking', '#4B7BEC') }
          { this.renderItem(data.helmetHires, '/report/category/helmet', 'Unreturned Helmets', 'unlink', '#10AC84') }
          { this.renderItem(data.cableLocks, '/report/category/riding', 'Unlocked Bikes', 'bicycle', '#833471') }
          { this.renderItem(data.pricingPlanApplications, '/plan-applications', 'Pricing Plans', 'id-card', '#1abc9c') }
          { this.renderItem(data.helmetSelfies, '/helmet-selfies', 'Helmet Selfies', 'hard-hat', '#A3CB38') }
          { this.renderItem(data.upcomingInspections, '/maintenance/schedule', 'Upcoming Inspections', 'tools', '#A55EEA') }
        </div>
        <div className="fm-dashboard-regions">
          { data.statuses && data.statuses.map(this.renderRegion) }
        </div>
        <div className="fm-dashboard-events">
          { data.events && <p className="fm-dashboard-events-title">Events</p> }
          { data.events && data.events.map(this.renderEvent) }
        </div>
        { error && <Toast>{ error }</Toast> }
      </div>
    );
  }
}

export default Dashboard;
