import React, { Component } from 'react';
import { Switch, Route } from 'react-router-dom';

import AnalyticsComplaints from './components/analytics-complaints/';
import AnalyticsEvents from './components/analytics-events/';
import AnalyticsLocation from './components/analytics-location/';
import AnalyticsHubs from './components/analytics-hubs/';
import AnalyticsMarketing from './components/analytics-marketing/';
import AnalyticsOperations from './components/analytics-operations/';
import AnalyticsKPIs from './components/analytics-kpis/';
import AnalyticsSafety from './components/analytics-safety/';
import AnalyticsReaction from './components/analytics-reaction/';
import AnalyticsWellington from './components/analytics-wellington/';

class Analytics extends Component {
  render() {
    return (
      <Switch>
        <Route path="/analytics/complaints" component={AnalyticsComplaints} />
        <Route path="/analytics/marketing" component={AnalyticsMarketing} />
        <Route path="/analytics/location" component={AnalyticsLocation} />
        <Route path="/analytics/hubs" component={AnalyticsHubs} />
        <Route path="/analytics/operations" component={AnalyticsOperations} />
        <Route path="/analytics/kpis" component={AnalyticsKPIs} />
        <Route path="/analytics/safety" component={AnalyticsSafety} />
        <Route path="/analytics/reaction" component={AnalyticsReaction} />
        <Route path="/analytics/wellington" component={AnalyticsWellington} />
        <Route path="/analytics" component={AnalyticsEvents} />
      </Switch>
    );
  }
}

export default Analytics;
