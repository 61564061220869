import React, { Component } from 'react';
import { Switch, Route } from 'react-router-dom';
import Toast from 'components/common/toast';
import api from 'helpers/api';
import history from 'helpers/history';
import parseCoordinates from 'helpers/coordinates';

import ZoneNewMap from './components/zone-new-map/';
import ZoneNewDetails from './components/zone-new-details/';

const defaultZone = {
  name: '',
  message: '',
  type: 'NOPARKING',
  isActive: false,
  canHire: true,
  canRide: true,
  canPark: true,
  speedLimit: null,
  startTime: '00:00:00',
  endTime: '24:00:00',
  regionId: 1,
  monday: true,
  tuesday: true,
  wednesday: true,
  thursday: true,
  friday: true,
  saturday: true,
  sunday: true,
};

class ZoneNew extends Component {
  constructor(props) {
    super(props);

    this.state = {
      zone: defaultZone,
      coordinates: [],
      regions: [],
    };

    this.fetchRegions = this.fetchRegions.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleCoordinates = this.handleCoordinates.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleError = this.handleError.bind(this);
  }

  componentDidMount() {
    document.title = 'New Zone | Flamingo Admin';
    if (this.props.location.pathname === '/zone/new/details' && this.state.coordinates.length === 0) {
      history.push('/zone/new');
    }
    this.fetchRegions();
  }

  /* ------ Controls ------ */

  handleChange(e) {
    const { zone } = this.state;
    const key = e.target.getAttribute('field');
    zone[key] = e.target.value === 'true' ? true : (e.target.value === 'false' ? false : (e.target.value === 'null' ? null : (isNaN(e.target.value) ? e.target.value : parseInt(e.target.value))));
    this.setState({ zone, error: false });
  }

  handleCoordinates(coordinates) {
    this.setState({ coordinates });
  }

  /* ------ Networking ------ */

  handleSubmit() {
    const zone = this.state.zone;
    let coordinates = parseCoordinates(this.state.coordinates);
    if (coordinates.length === 0) {
      this.setState({ error: 'No coordinates set.' });
      return;
    }
    this.setState({ loading: true, error: false });
    coordinates.push(coordinates[0]);
    return api.post(`/zone`, { coordinates, ...zone })
      .then(() => history.push('/zone'))
      .catch(this.handleError);
  }

  fetchRegions() {
    return api.get('/region/all')
      .then((res) => this.setState({ regions: res.data.data }))
      .catch(this.handleError);
  }

  handleError(e) {
    const validation = window.access(() => e.response.data.validation[0]) ? `Invalid ${e.response.data.validation[0]}` : false;
    const error = window.access(() => e.response.data.code) ? e.response.data.code : 'Something went wrong';
    this.setState({ error: validation || error, loading: false });
  }

  /* ------ Renders ------ */

  render() {
    const { zone, coordinates, regions, error } = this.state;
    return (
      <div className="fm-zone-new">
        <Switch>
          <Route path="/zone/new/details">
            <ZoneNewDetails zone={zone} regions={regions} onChange={this.handleChange} onSubmit={this.handleSubmit} />
          </Route>
          <Route path="/zone/new">
            <ZoneNewMap coordinates={coordinates} onCoordinates={this.handleCoordinates} onError={this.handleError} />
          </Route>
        </Switch>
        { error && <Toast>{ error }</Toast> }
      </div>
    );
  }
}

export default ZoneNew;
