import React, { Component } from 'react';
import NavigationBar from 'components/common/navigation-bar';
import { Link } from 'react-router-dom';
import Toast from 'components/common/toast';
import api from 'helpers/api';
import colors from 'helpers/colors';

class ZoneSearch extends Component {
  constructor(props) {
    super(props);
    this.state = {
      zones: [],
      error: false,
      loading: false,
    };

    this.loadRecentZones = this.loadRecentZones.bind(this);
    this.onQuery = this.onQuery.bind(this);
    this.searchZones = this.searchZones.bind(this);
    this.handleError = this.handleError.bind(this);
  }

  componentDidMount() {
    document.title = 'Zone Search | Flamingo Admin';
    this.loadRecentZones();
  }

  /* ------ Networking ------ */

  loadRecentZones() {
    return api.get(`/zone/recent`)
      .then((res) => this.setState({ zones: res.data.data }))
      .catch(this.handleError);
  }

  searchZones() {
    const { query } = this.state;
    if (query.length > 3) {
      this.setState({ zones: [], loading: true, error: false });
      return api.get(`/zone/search?query=${query}`)
        .then((res) => this.setState({ zones: res.data.data, loading: false }))
        .catch(this.handleError);
    }
  }

  handleError(e) {
    const error = window.access(() => e.response.data.code) ? e.response.data.code : 'Something went wrong';
    this.setState({ error, loading: false });
  }

  /* ------ Controls ------ */

  onQuery(e) {
    this.setState({ query: e.target.value }, this.searchZones);
  }

  /* ------ Renders ------ */

  renderZone(zone, i) {
    const statusClass = zone.isActive ? 'fm-zone-search-item-status fm-zone-search-item-status-active' : 'fm-zone-search-item-status fm-zone-search-item-status-inactive';
    return (
      <Link className="fm-zone-search-item" to={{ pathname: `/zone/${zone.id}` }} key={i}>
        <div className={statusClass}></div>
        <p className="fm-zone-search-item-name">{ zone.name }</p>
        <p className="fm-zone-search-item-type" style={{ backgroundColor: colors.zone[zone.type] }}>{ zone.type }</p>
      </Link>
    );
  }

  render() {
    const { loading, zones, query, error } = this.state;

    return (
      <div className="fm-zone-search">
        <NavigationBar title="Zone Search" loading={loading} showBack={true} />
        <div className="fm-zone-search-content">
          <div className="fm-zone-search-input">
            <input className="fm-input" value={query} onChange={this.onQuery} placeholder="Type to search zones..." />
          </div>
          <div className="fm-zone-search-items">
            { zones.map(this.renderZone) }
          </div>
        </div>
        { error && <Toast>{error}</Toast> }
      </div>
    );
  }
}

export default ZoneSearch;
