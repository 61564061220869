import React, { Component } from 'react';
import NavigationBar from 'components/common/navigation-bar';

const types = [
  { value: 'NORIDING', label: 'No Riding Zone' },
  { value: 'NOPARKING', label: 'No Parking Zone' },
  { value: 'LOWSPEED', label: 'Low Speed Zone' },
  { value: 'LOWSPEEDNOPARKING', label: 'Low Speed No Parking Zone' },
  { value: 'NOHIRE', label: 'No Hire Zone' },
  { value: 'PUBLICTRANSPORT', label: 'Public Transport Zone' },
  { value: 'HIDDEN', label: 'Hidden Zone' },
  { value: 'REMINDER', label: 'Reminder Zone' },
];

const speedLimits = [
  { value: null, label: 'No Speed Limit' },
  { value: 6, label: '6 km/h' },
  { value: 10, label: '10 km/h' },
  { value: 15, label: '15 km/h' },
  { value: 20, label: '20 km/h' },
  { value: 25, label: '25 km/h' },
];

const booleans = [
  { value: true, label: 'Yes' },
  { value: false, label: 'No' },
];

class ZoneNewDetails extends Component {
  render() {
    const { loading, regions, zone } = this.props;

    return (
      <div className="fm-zone-new-details">
        <NavigationBar title="New Zone Details" showBack={true} loading={loading} />
        <div className="fm-zone-new-details-content">
          <div className="fm-zone-new-details-input">
            <p className="fm-input-label">Name</p>
            <input value={zone.name} className="fm-input" field="name" onChange={this.props.onChange} />
          </div>
          <div className="fm-zone-new-details-input">
            <p className="fm-input-label">Description</p>
            <input value={zone.message} className="fm-input" field="message" onChange={this.props.onChange} />
          </div>
          <div className="fm-zone-new-details-input">
            <p className="fm-input-label">Type (For App Map)</p>
            <select value={zone.type} className="fm-input fm-input-select" field="type" onChange={this.props.onChange}>
              { types.map((type, i) => <option key={i} value={type.value}>{type.label}</option>) }
            </select>
          </div>
          <div className="fm-zone-new-details-input">
            <p className="fm-input-label">Speed Limit</p>
            <select value={zone.speedLimit || 'null'} className="fm-input fm-input-select" field="speedLimit" onChange={this.props.onChange}>
              { speedLimits.map((speedLimit, i) => <option key={i} value={speedLimit.value || 'null'}>{speedLimit.label}</option>) }
            </select>
            <p className="fm-input-hint">Use 6km/h for No Riding Zones, 15km/h for Low Speed Zones and No Speed Limit for all other zones.</p>
          </div>
          <div className="fm-zone-new-details-input">
            <p className="fm-input-label">Region</p>
            <select value={zone.regionId} className="fm-input fm-input-select" field="regionId" onChange={this.props.onChange}>
              { regions.map((option, i) => <option key={i} value={option.id}>{option.name}</option>) }
            </select>
          </div>
          <div className="fm-zone-new-details-input">
            <p className="fm-input-label">Active</p>
            <select value={zone.isActive} className="fm-input fm-input-select" field="isActive" onChange={this.props.onChange}>
              { booleans.map((option, i) => <option key={i} value={option.value}>{option.label}</option>) }
            </select>
            <p className="fm-input-hint">Use "No" if further setup is required, like time/day restrictions.</p>
          </div>
          <div className="fm-zone-new-details-input">
            <p className="fm-input-label">Can Hire (Start Trip)</p>
            <select value={zone.canHire} className="fm-input fm-input-select" field="canHire" onChange={this.props.onChange}>
              { booleans.map((option, i) => <option key={i} value={option.value}>{option.label}</option>) }
            </select>
            <p className="fm-input-hint">Usually Yes, unless this is a No Hire Zone.</p>
          </div>
          <div className="fm-zone-new-details-input">
            <p className="fm-input-label">Can Ride (Throttle Enabled)</p>
            <select value={zone.canRide} className="fm-input fm-input-select" field="canRide" onChange={this.props.onChange}>
              { booleans.map((option, i) => <option key={i} value={option.value}>{option.label}</option>) }
            </select>
            <p className="fm-input-hint">Usually Yes, unless this is a No Riding Zone.</p>
          </div>
          <div className="fm-zone-new-details-input">
            <p className="fm-input-label">Can Park (End Trip)</p>
            <select value={zone.canPark} className="fm-input fm-input-select" field="canPark" onChange={this.props.onChange}>
              { booleans.map((option, i) => <option key={i} value={option.value}>{option.label}</option>) }
            </select>
            <p className="fm-input-hint">Set to No for No Parking and No Riding Zones.</p>
          </div>
          <button className="fm-task-new-button" onClick={this.props.onSubmit}>{ loading ? 'Loading...' : 'Add Zone' }</button>
        </div>
      </div>
    );
  }
}

export default ZoneNewDetails;
