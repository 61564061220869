import React, { Component } from 'react';
import NavigationBar from 'components/common/navigation-bar';
import Toast from 'components/common/toast';
import history from 'helpers/history';
import api from 'helpers/api';

class DocumentTemplateEdit extends Component {
  constructor(props) {
    super(props);
    const preloadedTemplate = props.location.state ? props.location.state.template : false;
    this.state = {
      template: preloadedTemplate || {},
      loading: true,
      error: false,
    };
    this.loadTemplate = this.loadTemplate.bind(this);
    this.onSave = this.onSave.bind(this);
    this.onChangeMarkdown = this.onChangeMarkdown.bind(this);
    this.handleError = this.handleError.bind(this);
  }

  componentDidMount() {
    if (this.state.template.title) {
      document.title = `${this.state.template.title} | Flamingo Admin`;
    } else {
      document.title = 'Document Template | Flamingo Admin';
    }
    this.loadTemplate();
  }

  loadTemplate() {
    return api.get(`/document/template/${this.props.match.params.templateId}`)
      .then((res) => this.setState({ template: { ...this.state.template, ...res.data.data }, loading: false }, this.generatePeriods))
      .catch(this.handleError);
  }

  onSave() {
    this.setState({ loading: true });
    const { template } = this.state;
    return api.post(`/document/template/${template.id}`, { markdown: template.markdown })
      .then((res) => this.setState({ template: res.data.data, loading: false }, () => history.goBack()))
      .catch(this.handleError);
  }

  onChangeMarkdown(e) {
    const markdown = e.target.value;
    const { template } = this.state;
    template.markdown = markdown;
    this.setState({ template });
  }

  handleError(e) {
    const validation = window.access(() => e.response.data.validation[0]) ? `Invalid ${e.response.data.validation[0]}` : false;
    const error = window.access(() => e.response.data.code) ? e.response.data.code : 'Something went wrong';
    this.setState({ error: validation || error, loading: false });
  }

  render() {
    const { loading, error, template } = this.state;
    const title = template.title || 'Document Template';
    const saveAction = { onClick: this.onSave, icon: 'check', title: 'Save' };

    return (
      <div className="fm-document-template-edit">
        <NavigationBar title={title} loading={loading} showBack={true} rightActions={[saveAction]} />
        <div className="fm-document-template-edit-content">
          <div className="fm-document-template-edit-markdown">
            <textarea className="fm-document-template-edit-markdown-textarea" value={template.markdown} disabled={loading} onChange={this.onChangeMarkdown}></textarea>
          </div>
        </div>
        { error && <Toast>{error}</Toast> }
      </div>
    );
  }
}

export default DocumentTemplateEdit;
