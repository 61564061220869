import React, { Component } from 'react';
import { Switch, Route } from 'react-router-dom';

import ZoneMap from './components/zone-map/';
import ZoneNew from './components/zone-new/';
import ZoneView from './components/zone-view/';
import ZonePolygon from './components/zone-polygon/';
import ZoneChanges from './components/zone-changes/';
import ZoneSchedule from './components/zone-schedule/';
import ZoneSearch from './components/zone-search/';

class Zone extends Component {
  render() {
    return (
      <Switch>
        <Route path="/zone/changes" component={ZoneChanges} />
        <Route path="/zone/search" component={ZoneSearch} />
        <Route path="/zone/new" component={ZoneNew} />
        <Route path="/zone/:zoneId/schedule" component={ZoneSchedule} />
        <Route path="/zone/:zoneId/polygon" component={ZonePolygon} />
        <Route path="/zone/:zoneId" component={ZoneView} />
        <Route path="/zone" component={ZoneMap} />
      </Switch>
    );
  }
}

export default Zone;
