import React, { Component } from 'react';
import { Switch, Route } from 'react-router-dom';

import DocumentTemplates from './components/document-templates/';
import DocumentTemplate from './components/document-template/';
import DocumentTemplateEdit from './components/document-template-edit/';
import DocumentVersion from './components/document-version/';

class Document extends Component {
  render() {
    return (
      <Switch>
        <Route path="/document/:templateId/version/:versionId" component={DocumentVersion} />
        <Route path="/document/:templateId/edit" component={DocumentTemplateEdit} />
        <Route path="/document/:templateId" component={DocumentTemplate} />
        <Route path="/document" component={DocumentTemplates} />
      </Switch>
    );
  }
}

export default Document;
