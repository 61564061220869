import React, { Component } from 'react';
import NavigationBar from 'components/common/navigation-bar';
import Toast from 'components/common/toast';
import api from 'helpers/api';
import history from 'helpers/history';
import moment from 'moment';

const fields = [
  { value: 'name', label: 'Name' },
  { value: 'message', label: 'Message' },
  { value: 'type', label: 'Type' },
  { value: 'isActive', label: 'Active' },
  { value: 'speedLimit', label: 'Speed Limit' },
  { value: 'canPark', label: 'Can Park' },
  { value: 'canRide', label: 'Can Ride' },
  { value: 'canHire', label: 'Can Hire' },
  { value: 'startTime', label: 'Start Time' },
  { value: 'endTime', label: 'End Time' },
  { value: 'monday', label: 'Monday' },
  { value: 'tuesday', label: 'Tuesday' },
  { value: 'wednesday', label: 'Wednesday' },
  { value: 'thursday', label: 'Thursday' },
  { value: 'friday', label: 'Friday' },
];

const types = [
  { value: 'NORIDING', label: 'No Riding Zone' },
  { value: 'NOPARKING', label: 'No Parking Zone' },
  { value: 'LOWSPEED', label: 'Low Speed Zone' },
  { value: 'LOWSPEEDNOPARKING', label: 'Low Speed No Parking Zone' },
  { value: 'NOHIRE', label: 'No Hire Zone' },
  { value: 'PUBLICTRANSPORT', label: 'Public Transport Zone' },
  { value: 'HIDDEN', label: 'Hidden Zone' },
  { value: 'REMINDER', label: 'Reminder Zone' },
];

const speedLimits = [
  { value: null, label: 'No Speed Limit' },
  { value: 6, label: '6 km/h' },
  { value: 10, label: '10 km/h' },
  { value: 15, label: '15 km/h' },
  { value: 20, label: '20 km/h' },
  { value: 25, label: '25 km/h' },
];

const booleans = [
  { value: true, label: 'Yes' },
  { value: false, label: 'No' },
];

const times = [{ value: '00:00:00', label: '00:00' },{ value: '00:30:00', label: '00:30' },{ value: '01:00:00', label: '01:00' },{ value: '01:30:00', label: '01:30' },{ value: '02:00:00', label: '02:00' },{ value: '02:30:00', label: '02:30' },{ value: '03:00:00', label: '03:00' },{ value: '03:30:00', label: '03:30' },{ value: '04:00:00', label: '04:00' },{ value: '04:30:00', label: '04:30' },{ value: '05:00:00', label: '05:00' },{ value: '05:30:00', label: '05:30' },{ value: '06:00:00', label: '06:00' },{ value: '06:30:00', label: '06:30' },{ value: '07:00:00', label: '07:00' },{ value: '07:30:00', label: '07:30' },{ value: '08:00:00', label: '08:00' },{ value: '08:30:00', label: '08:30' },{ value: '09:00:00', label: '09:00' },{ value: '09:30:00', label: '09:30' },{ value: '10:00:00', label: '10:00' },{ value: '10:30:00', label: '10:30' },{ value: '11:00:00', label: '11:00' },{ value: '11:30:00', label: '11:30' },{ value: '12:00:00', label: '12:00' },{ value: '12:30:00', label: '12:30' },{ value: '13:00:00', label: '13:00' },{ value: '13:30:00', label: '13:30' },{ value: '14:00:00', label: '14:00' },{ value: '14:30:00', label: '14:30' },{ value: '15:00:00', label: '15:00' },{ value: '15:30:00', label: '15:30' },{ value: '16:00:00', label: '16:00' },{ value: '16:30:00', label: '16:30' },{ value: '17:00:00', label: '17:00' },{ value: '17:30:00', label: '17:30' },{ value: '18:00:00', label: '18:00' },{ value: '18:30:00', label: '18:30' },{ value: '19:00:00', label: '19:00' },{ value: '19:30:00', label: '19:30' },{ value: '20:00:00', label: '20:00' },{ value: '20:30:00', label: '20:30' },{ value: '21:00:00', label: '21:00' },{ value: '21:30:00', label: '21:30' },{ value: '22:00:00', label: '22:00' },{ value: '22:30:00', label: '22:30' },{ value: '23:00:00', label: '23:00' },{ value: '23:30:00', label: '23:30' },{ value: '24:00:00', label: '24:00' }];

const fieldValues = {
  isActive: { type: 'select', options: booleans },
  canPark: { type: 'select', options: booleans },
  canRide: { type: 'select', options: booleans },
  canHire: { type: 'select', options: booleans },
  startTime: { type: 'select', options: times },
  endTime: { type: 'select', options: times },
  speedLimit: { type: 'select', options: speedLimits },
  type: { type: 'select', options: types },
  name: { type: 'text' },
  message: { type: 'text' },
  monday: { type: 'select', options: booleans },
  tuesday: { type: 'select', options: booleans },
  wednesday: { type: 'select', options: booleans },
  thursday: { type: 'select', options: booleans },
  friday: { type: 'select', options: booleans },
};

class ZoneSchedule extends Component {
  constructor() {
    super();
    this.state = {
      field: 'name',
      newValue: '',
      changeAt: moment().add(1, 'day').format('YYYY-MM-DDTHH:mm'),
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.handleError = this.handleError.bind(this);
  }

  componentDidMount() {
    document.title = 'Schedule Zone | Flamingo Admin';
  }

  /* ------ Networking ------ */

  handleSave() {
    const { field, newValue, changeAt } = this.state;
    this.setState({ loading: true });
    const changeData = {
      field,
      newValue: `${newValue}`,
      changeAt: moment(changeAt).utc().format(),
    };
    return api.post(`/zone/${this.props.match.params.zoneId}/change`, changeData)
      .then(() => history.goBack())
      .catch(this.handleError);
  }

  handleError(e) {
    const validation = window.access(() => e.response.data.validation[0]) ? `Invalid ${e.response.data.validation[0]}` : false;
    const error = window.access(() => e.response.data.code) ? e.response.data.code : 'Something went wrong';
    this.setState({ error: validation || error, loading: false });
  }

  /* ------ Controls ------ */

  handleChange(e) {
    const stateUpdate = { error: false };
    const key = e.target.getAttribute('field');
    stateUpdate[key] = e.target.value === 'true' ? true : (e.target.value === 'false' ? false : (e.target.value === 'null' ? null : (isNaN(e.target.value) ? e.target.value : parseInt(e.target.value))));
    if (key === 'field') {
      const values = fieldValues[e.target.value];
      stateUpdate.newValue = values.options ? values.options[0].value : '';
    }
    this.setState(stateUpdate);
  }

  /* ------ Renders ------ */

  render() {
    const { loading, field, newValue, changeAt, error } = this.state;
    const value = fieldValues[field];

    return (
      <div className="fm-zone-schedule">
        <NavigationBar title="Schedule Zone Change" loading={loading} showBack={true} />
        <div className="fm-zone-schedule-form">
          <div className="fm-zone-schedule-input">
            <p className="fm-input-label">Field</p>
            <select value={field} className="fm-input fm-input-select" field="field" onChange={this.handleChange}>
              { fields.map((type, i) => <option key={i} value={type.value}>{type.label}</option>) }
            </select>
          </div>
          <div className="fm-zone-schedule-input">
            <p className="fm-input-label">New Value</p>
            {
              value.type === 'text' ? (
                <input value={newValue} className="fm-input" field="newValue" onChange={this.handleChange} />
              ) : (
                <select value={newValue} className="fm-input fm-input-select" field="newValue" onChange={this.handleChange}>
                  { value.options.map((type, i) => <option key={i} value={type.value}>{type.label}</option>) }
                </select>
              )
            }
          </div>
          <div className="fm-zone-schedule-input">
            <p className="fm-input-label">Change At</p>
            <input value={changeAt} className="fm-input" field="changeAt" type="datetime-local" onChange={this.handleChange} />
          </div>
          <button className="fm-task-new-button" onClick={this.handleSave}>{ loading ? 'Scheduling...' : 'Schedule' }</button>
        </div>
        { error && <Toast>{error}</Toast> }
      </div>
    );
  }
}

export default ZoneSchedule;
