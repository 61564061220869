import React, { Component } from 'react';
import NavigationBar from 'components/common/navigation-bar';
import { Link } from 'react-router-dom';
import Toast from 'components/common/toast';
import api from 'helpers/api';
import moment from 'moment';

const fieldLabels = {
  isActive: 'Active',
  canPark: 'Can Park',
  canRide: 'Can Ride',
  canHire: 'Can Hire',
  startTime: 'Start Time',
  endTime: 'End Time',
  speedLimit: 'Speed Limit',
  type: 'Type',
  message: 'Message',
  monday: 'Monday',
  tuesday: 'Tuesday',
  wednesday: 'Wednesday',
  thursday: 'Thursday',
  friday: 'Friday',
  name: 'Name',
};

const valueLabels = {
  'true': 'Yes',
  'false': 'No',
  '6': '6 km/h',
  '10': '6 km/h',
  '15': '15 km/h',
  '20': '20 km/h',
  '25': '25 km/h',
  'NULL': 'None',
};

class ZoneChanges extends Component {
  constructor(props) {
    super(props);
    this.state = {
      changes: [],
      error: false,
      loading: true,
    };

    this.loadChanges = this.loadChanges.bind(this);
    this.handleError = this.handleError.bind(this);
    this.renderChange = this.renderChange.bind(this);
  }

  componentDidMount() {
    document.title = 'Zone Changes | Flamingo Admin';
    this.loadChanges();
  }

  /* ------ Networking ------ */

  loadChanges() {
    return api.get(`/zone/changes`)
      .then((res) => this.setState({ changes: res.data.data, loading: false }))
      .catch(this.handleError);
  }

  handleError(e) {
    const error = window.access(() => e.response.data.code) ? e.response.data.code : 'Something went wrong';
    this.setState({ error, loading: false });
  }

  /* ------ Renders ------ */

  renderChange(change, i) {
    return (
      <Link className="fm-zone-changes-item" to={{ pathname: `/zone/${change.zone.id}` }} key={i}>
        <p className="fm-zone-changes-item-name">{ change.zone.name } <span className="fm-zone-changes-item-id">({ change.zone.id })</span></p>
        <p className="fm-zone-changes-item-field">{ fieldLabels[change.field] }</p>
        <p className="fm-zone-changes-item-value">{ valueLabels[change.newValue] || change.newValue }</p>
        <p className="fm-zone-changes-item-date">{ moment(change.changeAt).format('HH:mm DD-MM-YYYY') }</p>
      </Link>
    );
  }

  render() {
    const { loading, changes, error } = this.state;

    return (
      <div className="fm-zone-changes">
        <NavigationBar title="Zone Changes" loading={loading} showBack={true} />
        <div className="fm-zone-changes-items">
          { changes.map(this.renderChange) }
        </div>
        { error && <Toast>{error}</Toast> }
      </div>
    );
  }
}

export default ZoneChanges;
