import React, { Component } from 'react';
import NavigationBar from 'components/common/navigation-bar';
import Toast from 'components/common/toast';
import api from 'helpers/api';

class AnalyticsReaction extends Component {
  constructor() {
    super();
    this.state = { url: '', data: false, loading: false };

    this.loadData = this.loadData.bind(this);
    this.handleError = this.handleError.bind(this);
    this.handleUrlChange = this.handleUrlChange.bind(this);
  }

  componentDidMount() {
    document.title = 'Slack Reaction Check | Flamingo Admin';
  }

  loadData() {
    const { url } = this.state;
    const regex = /\/archives\/([A-Z0-9]+)\/p(\d{10})(\d{6})$/;
    const match = url.match(regex);
    if (!match) {
      this.setState({ error: 'Invalid Slack message URL' });
      return;
    }
    const channelId = match[1];
    const slackTs = `${match[2]}.${match[3]}`;
    this.setState({ loading: true, error: false, data: false });
    return api.post('/user/team/reaction-check', { channelId, slackTs })
      .then((res) => this.setState({ data: res.data.data, loading: false, error: false }))
      .catch(this.handleError);
  }

  handleError(err) {
    const errors = window.access(() => err.response.data.error.errors) ? err.response.data.error.errors : [];
    const error = window.access(() => errors[0].message) ? errors[0].message : (window.access(() => err.response.data.error.message) ? err.response.data.error.message : err.toString());
    this.setState({ error, loading: false });
  }

  handleUrlChange(e) {
    this.setState({ url: e.target.value });
  }

  render() {
    const { loading, data, url } = this.state;

    return (
      <div className="fm-analytics">
        <NavigationBar title="Slack Reaction Check" loading={loading} />
        <div className="fm-analytics-reaction">
          <div className="fm-analytics-reaction-form">
            <input value={url} className="fm-input" onChange={this.handleUrlChange} placeholder="Eg https://flamingo-scooters.slack.com/archives/CHGKWPHRT/p1743376542009469" />
            <button className="fm-analytics-reaction-button" onClick={this.loadData}>{ loading ? 'Loading...' : 'Check' }</button>
          </div>
          {
            data ? (
              <table className="fm-analytics-reaction-table">
                <tbody>
                  {
                    data.map((user, i) => {
                      return (
                        <tr key={i}>
                          <td className="fm-analytics-reaction-table-value">{ user.hasReacted ? '✅' : '❌' }</td>
                          <td className="fm-analytics-reaction-table-label">{ user.firstName } { user.lastName }</td>
                        </tr>
                      );
                    })
                  }
                </tbody>
              </table>
            ) : (
              <p className="fm-analytics-reaction-empty">{ loading ? 'Please wait...' : 'Enter the Slack URL above.' }</p>
            )
          }
        </div>
        { this.state.error && <Toast>{this.state.error}</Toast> }
      </div>
    );
  }
}

export default AnalyticsReaction;
